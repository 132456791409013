import Markdown from 'react-markdown';
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Img from 'gatsby-image';

function Vita (props) {
  const { data: { allMarkdownRemark: { edges: [data] }, site } } = props;
  const { node: { frontmatter: { vita, image } } } = data;
  function List (props) {
    return (
      <ul className={'mt-0'} type={'1'}>{props.children}</ul>
    );
  }
  function renderLink (props) {
    return (<a href={props.href} target={'_blank'}>{props.children}</a>);
  }

  return (
    <Layout title={site.siteMetadata.title}>
      <SEO title={`${site.siteMetadata.title} Vita`} description={'Franziska Schittler begann ihre Ausbildung zur Restauratorin mit einem zweijährigen studien-vorbereitenden Praktikum Restaurierungsatelier Ernst, München.'}/>
      <section className="section pb-0">
        <div className={'columns'}>
          <div className={'column is-10 is-offset-1'}>
            <h1 className="title">{'Vita'}</h1>
          </div>
        </div>
      </section>
      <section className="section">
        <div className={'columns'}>
          <div className={'column is-4 is-offset-1'}>
            <figure className="image">
              <Img fluid={image.childImageSharp.fluid} alt="Franziska Schittler Restaurierung München" objectFit="cover" />
            </figure>
          </div>
          <div className={'column is-5'}>
            <div className={'content is-size-5  has-text-left-mobile'}>
              <Markdown source={`${vita}`} renderers={{ list: List, link: renderLink }} />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default Vita;

export const pageQuery = graphql`
    query Vita {
        site {
            siteMetadata {
                title
            }
        }
        allMarkdownRemark(
            filter: { frontmatter: { templateKey: { eq: "vita" } } }
        ) {
            edges {
                node {
                    id
                    fields {
                        slug
                    }
                    frontmatter {
                        vita,
                        image {
                            childImageSharp {
                                fluid(maxWidth: 1024, , quality: 75) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
